import React, { useEffect, useState } from 'react'

import { claimSchool, getSchools, getSchoolsForUser } from '../../services/schools'
import { getCurrentUser } from '../../services/auth'
import EditSchool from '../EditSchool/EditSchool'
import School from '../Directory/School'


const YourSchools = () => {
  const [state, setState] = useState({
    schools: [],
    pendingSchools: [],
    hasScannedExistingSchools: false,
    loading: {
      schools: true,
      pending: false
    }
  })

  useEffect(() => {
    getSchoolsForUser(getCurrentUser()).then(schoolsDbSnapshot => {
      setState({
        schools: schoolsDbSnapshot,
        pendingSchools: state.pendingSchools,
        hasScannedExistingSchools: state.hasScannedExistingSchools,
        loading: {
          schools: false,
          pending: state.loading.pending
        }
      })
    })
  }, [])

  const getSchoolsElement = (schools) => {
    return schools.map(school => {
      return <EditSchool key={school.id} schoolId={school.id} school={school.data}></EditSchool>
    })
  }

  const handleScanForUsersUnclaimedSchools = () => {
    setState({
      schools: state.schools,
      pendingSchools: state.pendingSchools,
      hasScannedExistingSchools: state.hasScannedExistingSchools,
      loading: {
        schools: state.loading.schools,
        pending: true
      }
    })
    const user = getCurrentUser()
    const whereCondition = {field: 'email', operator: '==', value: user.email}

    getSchools(whereCondition)
    .then(schoolsDbSnapshot => {
      const pendingSchools = schoolsDbSnapshot.filter(schoolSnapshot => schoolSnapshot.data.owner !== user.uid)
      setState({
        schools: state.schools,
        pendingSchools: pendingSchools,
        hasScannedExistingSchools: true,
        loading: {
          schools: state.loading.schools,
          pending: false
        }
      })
    }).catch(error => {
      alert('An error occurred when trying to load your schools. Please check your internet connection and try again later')
      setState({
        schools: state.schools,
        pendingSchools: state.pendingSchools,
        hasScannedExistingSchools: true,
        loading: {
          schools: state.loading.schools,
          pending: false
        }
      })
    })
  }

  const getPreScanUI = () => {
    if (state.loading.pending) {
      return <p>Loading...</p>
    } else {
      return <>
        <p>Click the button below to scan our records for a kite school where the school email address matches the email address you used to sign in.</p>
        <button onClick={() => handleScanForUsersUnclaimedSchools()}>Scan existing schools</button>
      </>
    }
    
  }

  const handleClaimSchool = (schoolId) => {
    setState({
      schools: state.schools,
      pendingSchools: state.pendingSchools,
      hasScannedExistingSchools: state.pendingSchools,
      loading: {
        schools: state.loading.schools,
        pending: true
      }
    })
    claimSchool(schoolId).then(resp => {
      alert('The school has been transferred to your account. You can now change its settings.')

      const school = state.pendingSchools.find(school => school.id === schoolId)
      const user = getCurrentUser()
      school.data.owner = user.uid
      const userSchools = [...state.schools]
      userSchools.push(school)
      const pendingSchools = state.pendingSchools.filter(school => school.id !== schoolId)

      setState({
        schools: userSchools,
        pendingSchools: pendingSchools,
        hasScannedExistingSchools: state.pendingSchools,
        loading: {
          schools: state.loading.schools,
          pending: false
        }
      })
    }).catch(err => {
      alert('An error occurred when trying to change the school ownership. Please try again later or get in touch at info@caniteachmyselfkiteboarding.com')
      setState({
        schools: state.schools,
        pendingSchools: state.pendingSchools,
        hasScannedExistingSchools: state.pendingSchools,
        loading: {
          schools: state.loading.schools,
          pending: false
        }
      })
    })
  }

  const getPendingSchoolUI = (school) => {
    return <div key={school.id}>
      <button type="button" onClick={() => handleClaimSchool(school.id)}>Claim {school.data.name}</button>
      <School school={school.data}></School>
    </div>
  }

  const getPostScanUI = () => {
    if (state.loading.pending) {
      return <p>Loading...</p>
    } else if (state.pendingSchools.length) {
      const plural = state.pendingSchools.length !== 1
      return <>
        <p>We have found {state.pendingSchools.length} school{plural ? 's' : ''} registered with your email address.Click the "claim" button{plural ? 's' : ''} to add {plural ? 'them' : 'it'} to your account and have total control over {plural ? 'their' : 'its'} content.</p>
        {state.pendingSchools.map(school => getPendingSchoolUI(school))}
      </>
    } else {
      return <>
        <p>We can't find any schools whose email address matches the email address you used to sign in. If your school is visible on this site but has the wrong contact email address please send an email to <a href="mailto:info@caniteachmyselfkiteboarding.com">info@caniteachmyselfkiteboarding.com</a> and we will be able to help you. Make sure you include the school name and country so we are able to identify it.</p>
        <p>Similarly, if your school doesn't exist on this site, we would love to add it. Drop us an email and we will help you get your school set up so it's visible to all our site visitors.</p>
      </>
    }
    
  }

  const getPendingSchoolsUI = () => {
    return <>
      <h2>Claim Schools</h2>
      <p>If one of your schools already exists in our school directory, you will be able to claim it, adding it to your account. To do this, we need to be 100% sure the school really is yours.</p>

      {state.hasScannedExistingSchools ? getPostScanUI() : getPreScanUI()}
    </>
  }

  const getSchoolsSection = () => {
    return <>
      {state.schools.length ? <p>Below is a list of the schools you manage. Use this page to update the details or to promote them.</p> : <p>You don't currently have any schools</p>}
      {getSchoolsElement(state.schools)}
    </>
  }

  return <>
    <h2>Your Schools</h2>
    {state.loading.schools ? <p>Loading schools...</p> : getSchoolsSection()}
    {getPendingSchoolsUI()}    
  </>
}

export default YourSchools
