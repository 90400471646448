import React, { useState } from 'react'
import { changePassword } from '../../services/auth'


const ChangePassword = () => {
  const initialState = {
    currentPassword: '',
    newPassword: '',
    newPassword2: '',
    hasChangedPassword: false
  }
  const [state, setState] = useState(initialState)

  const handleUpdate = event => {
    const newState = { ...state }
    newState[event.target.name] = event.target.value

    setState(newState)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const passwordValidation = verifyPassword()
    if (!passwordValidation.valid) {
      alert(passwordValidation.message)
    } else {
      changePassword(state.currentPassword, state.newPassword)
        .then((result) => {
          alert('Your password has been changed.')
          setState({...initialState, hasChangedPassword: true})
          // XXX: Hide the form and show a 'password has been changed message'
        })
        .catch(err => alert(err.message))
    }
  }

  const verifyPassword = () => {
    // TODO: Move to a service, because it's also used when creating an account (Signup.js)
    const passwordValidation = { valid: false, message: '' }
    if (!state.newPassword || !state.newPassword2) {
      passwordValidation.message = 'You must type a password into the \'password\' and \'retype password\' fields'
      return passwordValidation;
    }
    if (state.newPassword !== state.newPassword2) {
      passwordValidation.message = 'Both password fields must be exactly the same'
      return passwordValidation;
    }
    passwordValidation.valid = true
    return passwordValidation
  }

  const getPasswordResetForm = () => {
    return <>
      <p>Update your password using the form below.</p>
      <form
        method="post"
        onSubmit={event => {
          handleSubmit(event)
        }}
      >
        <div className="form-group">
          <label htmlFor="currentPassword">Current Password</label>
          <input
            type="password"
            name="currentPassword"
            onChange={handleUpdate}
          />
        </div>
        <div className="form-group">
          <label htmlFor="newPassword">New Password</label>
          <input
            type="password"
            name="newPassword"
            onChange={handleUpdate}
          />
        </div>
        <div className="form-group">
          <label htmlFor="newPassword2">Retype New Password</label>
          <input
            type="password"
            name="newPassword2"
            onChange={handleUpdate}
          />
        </div>

        <button type="submit">Change Password</button>
      </form>
    </>
  }

  return <>
    <h2>Change Password</h2>
    {state.hasChangedPassword ? <p>Your password has been changed.</p> : getPasswordResetForm()}
  </>
}

export default ChangePassword
