import React, { useState } from 'react'

import accountDetailStyles from './accountDetails.module.css'
import CloseAccount from '../CloseAccount/CloseAccount'
import { getCurrentUser } from '../../services/auth'


const AccountDetails = () => {
  const user = getCurrentUser()

  const [state, setState] = useState({user, editing: {field: '', value: ''}})

  const setEditingField = (fieldName) => {
    const newState = { ...state }
    newState.editing.field = fieldName

    setState(newState)
  }

  const getPropertyGroup = (label, fieldName, propertyName) => {
    return <div>
      <strong>{label}:</strong>
      <div className={accountDetailStyles.propertyGroup}>
        {propertyName}&nbsp;
        {/* <button className={accountDetailStyles.editButton} onClick={() => setEditingField(fieldName)}>Edit</button> */}
      </div>
    </div>
  }

  const handleUpdate = event => {
    const newState = { ...state }
    newState.editing.value = event.target.value

    setState(newState)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
  }

  const getEditEmailForm = () => {
    return <form
      method="post"
      onSubmit={event => {
        handleSubmit(event)
      }}
    >
      <strong>Email address:</strong>
      <div className="form-group">
        <label htmlFor="email">Email</label>
        <input
          type="email"
          name="email"
          onChange={handleUpdate}
        />
      </div>
      <button type="submit" onClick={() => setEditingField('')}>Update email</button>
    </form>
  }


  return <>
    <h2>Account Details</h2>
    {/* <p>Use this page to update your account details.</p> */}
    <p>Use this page to view your account details.</p>

    {state.editing.field === 'email' ? getEditEmailForm() : getPropertyGroup('Email Address', 'email', state.user.email)}

    <CloseAccount></CloseAccount>
    
  </>
}

export default AccountDetails
