import React, {useState} from 'react'

import { updateSchool } from '../../services/schools'
import { scheduleUpdate } from '../../services/system'
import editSchoolStyles from './editSchool.module.css'
import { countries } from '../../services/countries'

const EditSchool = (props) => {
  const getSchoolEditableFields = (school) => {
    const editableFields = [
      'about', 'address', 'country', 'email', 'features', 'name', 'phone', 'website'
    ]
    const editableSchool = {}
    for (let fieldName of editableFields) {
      editableSchool[fieldName] = school[fieldName] || ''
    }
    return editableSchool
  }

  const [state, setState] = useState({
    schoolSnapshot: {...props.school},
    // school: {...props.school},
    school: getSchoolEditableFields(props.school),
    hasChanges: false,
    loading: false
  })

  const handleUpdate = event => {
    const newState = { ...state }
    newState.school[event.target.name] = event.target.value
    newState.hasChanges = true;

    setState(newState)
  }

  const handleFeaturesUpdate = event => {
    const newState = { ...state }
    let featuresList = [...state.school.features]
    if (event.target.checked) {
      // Checked - Addition
      featuresList.push(event.target.name)
    } else {
      // Unchecked - Removal
      featuresList = featuresList.filter(featureName => featureName !== event.target.name)
    }
    newState.school.features = featuresList
    newState.hasChanges = true;

    setState(newState)
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    // Save the state data for use later. This means I don't have to get it from
    // state each time I update the state, which is unreliable when calling
    // multiple times in one function
    const school = {...state.school}
    let schoolSnapshot = {...state.schoolSnapshot}
    let hasChanges = state.hasChanges

    // Set loading
    const newState = {
      schoolSnapshot: {...schoolSnapshot},
      school: {...school},
      hasChanges: hasChanges,
      loading: true
    }
    
    setState(newState)
    
    updateSchool(props.schoolId, state.school)
    .catch(err => {
      throw new Error('There was an error updating your school. Please refresh the page and try again')
    })
    .then(() => {
      // Set the snapshot to be the edited school
      schoolSnapshot = school
      const newState = {
        schoolSnapshot: {...schoolSnapshot},
        school: {...school},
        hasChanges: false,
        loading: true
      }
      setState(newState)
      return scheduleUpdate()
    })
    .catch(err => {
      throw new Error('The changes have been saved but could not be reflected on the homepage. If you school details have not been refreshed in the next 24 hours, please contact us.')
    })
    .then(() => {
      alert('The details have been saved. The changes can take up to four hours to be reflected in the schools directory.')
    })
    .then(() => {
      const newState = {
        schoolSnapshot: {...schoolSnapshot},
        school: {...school},
        hasChanges: true,
        loading: false
      }
      setState(newState)
    })
    .catch(err => alert(err.message))
  }

  const cancelHandler = () => {
    setState({
      schoolSnapshot: {...state.schoolSnapshot},
      school: {...state.schoolSnapshot},
      hasChanges: false,
      loading: state.loading
    })
  }

  const getPromotedUI = (school) => {
    if (school.promoted) {
      return <span className={editSchoolStyles.promotedText}>PROMOTED</span>
    } else {
      return <span className={editSchoolStyles.notPromotedText}>This school is not yet promoted. (Promoting schools coming soon!)</span>
    }
  }

  const getCountryOptions = () => {
    return countries.map(countryData => {
      return <option value={countryData.name} key={countryData.name}>
        {countryData.name}
      </option>
    })
  }
  
  // TODO: Test for HTML injection vulnerabilities
  return <div>
    <h3>{state.schoolSnapshot.name}</h3>
    {getPromotedUI(state.schoolSnapshot)}

    <form
      onSubmit={event => {
        handleSubmit(event)
      }}
    >
      <div className="form-group">
        <label htmlFor="name">Name</label>
        <input
          type="text"
          name="name"
          onChange={handleUpdate}
          value={state.school.name}
          disabled={state.loading}
        />
      </div>
      <div className="form-group">
        <label htmlFor="about">About</label>
        <textarea
          name="about"
          onChange={handleUpdate}
          value={state.school.about}
          disabled={state.loading}
        ></textarea>
      </div>
      <div className="form-group">
        <label htmlFor="country">Country</label>
        <select
          name="country"
          onChange={handleUpdate}
          value={state.school.country}
          disabled={state.loading}
        >
          {getCountryOptions()}
        </select>
      </div>
      <div className="form-group">
        <label htmlFor="address">Address</label>
        <textarea
          name="address"
          onChange={handleUpdate}
          value={state.school.address}
          disabled={state.loading}
        ></textarea>
      </div>
      <div className="form-group">
        <label htmlFor="email">Email</label>
        <input
          type="email"
          name="email"
          onChange={handleUpdate}
          value={state.school.email}
          disabled={state.loading}
        />
      </div>
      <div className="form-group">
        <label htmlFor="website">Website</label>
        <input
          type="text"
          name="website"
          onChange={handleUpdate}
          value={state.school.website}
          disabled={state.loading}
        />
      </div>
      <div className="form-group">
        <label htmlFor="phone">Phone</label>
        <input
          type="text"
          name="phone"
          onChange={handleUpdate}
          value={state.school.phone}
          disabled={state.loading}
        />
      </div>

      <p>Check the features offered at your school. This helps people find you.</p>
      <ul className={editSchoolStyles.featureList}>
        <li key="waves">
          <input
            id="waves"
            type="checkbox"
            name="waves"
            checked={state.school.features.includes('waves')}
            onChange={handleFeaturesUpdate}
            disabled={state.loading}
          />
          <label htmlFor="waves">Waves</label>
        </li>
        <li key="flatwater">
          <input
            id="flatwater"
            type="checkbox"
            name="flatwater"
            checked={state.school.features.includes('flatwater')}
            onChange={handleFeaturesUpdate}
            disabled={state.loading}
          />
          <label htmlFor="flatwater">Flat water</label>
        </li>  
        <li key="hydrofoil">
          <input
            id="hydrofoil"
            type="checkbox"
            name="hydrofoil"
            checked={state.school.features.includes('hydrofoil')}
            onChange={handleFeaturesUpdate}
            disabled={state.loading}
          />
          <label htmlFor="hydrofoil">Hydrofoil</label>
        </li>
        <li key="downwinder">
          <input
            id="downwinder"
            type="checkbox"
            name="downwinder"
            checked={state.school.features.includes('downwinder')}
            onChange={handleFeaturesUpdate}
            disabled={state.loading}
          />
          <label htmlFor="downwinder">Downwinder</label>
        </li>
        <li key="snowkite">
          <input
            id="snowkite"
            type="checkbox"
            name="snowkite"
            checked={state.school.features.includes('snowkite')}
            onChange={handleFeaturesUpdate}
            disabled={state.loading}
          />
          <label htmlFor="snowkite">Snowkite</label>
        </li>
        <li key="landboard">
          <input
            id="landboard"
            type="checkbox"
            name="landboard"
            checked={state.school.features.includes('landboard')}
            onChange={handleFeaturesUpdate}
            disabled={state.loading}
          />
          <label htmlFor="landboard">Landboarding</label>
        </li>
        <li key="buggy">
          <input
            id="buggy"
            type="checkbox"
            name="buggy"
            checked={state.school.features.includes('buggy')}
            onChange={handleFeaturesUpdate}
            disabled={state.loading}
          />
          <label htmlFor="buggy">Kite buggy</label>
        </li>
        <li key="radiohelmet">
          <input
            id="radiohelmet"
            type="checkbox"
            name="radiohelmet"
            checked={state.school.features.includes('radiohelmet')}
            onChange={handleFeaturesUpdate}
            disabled={state.loading}
          />
          <label htmlFor="radiohelmet">Radio helmet</label>
        </li>
        <li key="nowind">
          <input
            id="nowind"
            type="checkbox"
            name="nowind"
            checked={state.school.features.includes('nowind')}
            onChange={handleFeaturesUpdate}
            disabled={state.loading}
          />
          <label htmlFor="nowind">No wind options</label>
        </li>
        <li key="boat">
          <input
            id="boat"
            type="checkbox"
            name="boat"
            checked={state.school.features.includes('boat')}
            onChange={handleFeaturesUpdate}
            disabled={state.loading}
          />
          <label htmlFor="boat">Boat support</label>
        </li>
        <li key="accommodation">
          <input
            id="accommodation"
            type="checkbox"
            name="accommodation"
            checked={state.school.features.includes('accommodation')}
            onChange={handleFeaturesUpdate}
            disabled={state.loading}
          />
          <label htmlFor="accommodation">Accommodation</label>
        </li>
      </ul>

      {/* TODO: Add validation. Country and name are mandatory. */}
      <div className={editSchoolStyles.formButtons}>
        <button type="button" onClick={cancelHandler}>Cancel</button>
        <button type="submit" disabled={!state.hasChanges || state.loading}>Save</button>
      </div>
    </form>
  </div>
}

// TODO: These 'features' should be defined in the app somewhere so I don't have to go find them in the icon list every time
// waves: wave,
// flatwater: flatwater,
// hydrofoil: hydrofoil,
// downwinder: downwind,
// snowkite: snow,
// landboard: landboard,
// buggy: buggy

// XXX: Somehow I need to make sure the owner and the promoted flag is never changed by the user through inspecting the elements and manually changing. See https://stackoverflow.com/questions/51769592/firebase-how-to-prevent-writing-a-specific-field-in-firestore

export default EditSchool
