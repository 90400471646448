import { navigate } from 'gatsby'
import React, { useState } from 'react'

import { closeAccount, getCurrentUser } from '../../services/auth'


const CloseAccount = () => {
  const user = getCurrentUser()

  const [state, setState] = useState({closingAccount: false, password: ''})

  const getUI = () => {
    if (state.closingAccount) {
      return <>
        <p>You have chosen to close your account. This action is not reversible. To continue, please re-confirm your password and click the 'Permanently close my account' button.</p>
        <form
          onSubmit={event => {
            handleCloseAccount(event)
          }}
        > 
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              name="password"
              onChange={handleUpdatePassword}
            />
          </div>


          <button type="submit" disabled={!state.password}>Permanently close my account</button>
        </form>
      </>
    } else {
      return <>
        <p>You can close your account by clicking the button below. This will permanently delete your information from our database and remove you as the owner of any schools you manage. You will no longer be able to log in or edit any school details.</p>
        <button onClick={handleBeginClosingAccount}>Close my account</button>
      </>
    }
  }

  const handleUpdatePassword = event => {
    const newState = { ...state }
    newState.password = event.target.value
    setState(newState)
  }

  const handleBeginClosingAccount = () => {
    const newState = {...state, closingAccount: true}
    setState(newState)
  }

  const handleCloseAccount = (event) => {
    event.preventDefault()

    closeAccount(state.password)
    .then((r) => {
      alert('You account has been closed.')
      navigate('/')
    })
    .catch((error) => {
      alert(`There was a problem closing your account.\n\n${error.message}\n\nPlease try again.`)
    })
  }

  return <>
    <h3>Close Account</h3>
    {getUI()}
  </>
}

export default CloseAccount
