import React from 'react'

import { isBrowser } from '../../../services/auth'
import dashboardStyles from './dashboard.module.css'
import YourSchools from '../../../components/YourSchools/YourSchools'
import ChangePassword from '../../../components/ChangePassword/ChangePassword'
import AccountDetails from '../../../components/AccountDetails/AccountDetails'


class Dashboard extends React.Component {

  defaultView = 'schools';

  constructor (props) {
    super(props)
    this.state = {activeView: isBrowser() && window.location.hash || this.defaultView}
  }

  setActiveView (viewName) {
    this.setState({activeView: viewName})
  }
  
  render () {
    return <section className={dashboardStyles.sidebarContainer}>
      <div>
        <nav className={dashboardStyles.sidebar}>
          <ul className="stack-small">
            <li><a href="#schools" onClick={() => this.setActiveView('schools')}>Your schools</a></li>
            <li><a href="#details" onClick={() => this.setActiveView('details')}>Account Details</a></li>
            <li><a href="#password" onClick={() => this.setActiveView('password')}>Password</a></li>
          </ul>
        </nav>
        {this.state.activeView === 'schools' ? <article className={dashboardStyles.content}><YourSchools></YourSchools></article> : ''}
        {this.state.activeView === 'details' ? <article className={dashboardStyles.content}><AccountDetails></AccountDetails></article> : ''}
        {this.state.activeView === 'password' ? <article className={dashboardStyles.content}><ChangePassword></ChangePassword></article> : ''}
      </div>
    </section>
  }
}

export default Dashboard
